import { useMemo } from 'react';

import { Locale } from '../../core/i18n/Locale';
import { getSiteByLocale } from '../../core/Site/Site';

export const useSite = (locale: Locale) => {
    return useMemo(() => {
        return getSiteByLocale(locale);
    }, [locale]);
};
