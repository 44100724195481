const localesConfig = require('../locales.json');

const ALL_LOCALES = Object.keys(localesConfig.all);

const ALL_SITES = ALL_LOCALES.map((locale) => {
    const siteConfig = require(`../sites/${locale}.json`);
    return siteFactory(siteConfig);
});

function siteFactory(siteConfig) {
    const host = process.env.NEXT_PUBLIC_HOST_SUBDOMAINS + siteConfig.host;

    return {
        ...siteConfig,
        host,
        url: `${siteConfig.protocol}://${host}`,
    };
}

function allSites() {
    return ALL_SITES;
}

function siteByHost(host) {
    return allSites().find((site) => {
        return site.host === host;
    });
}

module.exports = {
    allSites,
    siteByHost,
    siteFactory,
};
