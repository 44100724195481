import config from '../../configurations/assets.json';
import { assetPrefix } from '../../configurations/nextjs/assetPrefix';

export function staticCdnUrl(path?: string) {
    const baseUrl = `${config.staticCdn.protocol}://${config.staticCdn.domain}/`;
    return path ? baseUrl + path : baseUrl;
}

export function assetCdnUrl(path?: string) {
    const baseUrl = `${config.assetsCdn.protocol}://${config.assetsCdn.domain}/`;
    return path ? baseUrl + path : baseUrl;
}

/**
 * Base static path for Pyrite assets is /static/
 * The CDN is plugged on this base path.
 */
export function pyriteStaticPath(path: string) {
    return config.staticPath + path;
}

/**
 * Base static path for Next.js assets is:
 * - production env: /static/_next/static/ (served by same CDN as Pyrite)
 * - development env: /_next/static/ (served by Next.js Dev server)
 */
export function nextjsStaticPath(path: string) {
    const prefix = assetPrefix() ?? '/';
    return `${prefix}_next/static/${path}`;
}
