import localesConfig from '../../configurations/locales.json';
import { allSites } from '../../configurations/nextjs/site';

import { Currency } from '../../resources/currency/domain/types/currency';
import { Locale, LocaleEvaneos, LocalePrismic } from '../i18n/Locale';

const ALL_SITES = allSites() as Site[];

const defaultLocale = localesConfig.default as Locale;

export function getAllSites() {
    return ALL_SITES;
}

export function getAllSitesExcludingLocales(excludeLocales: Array<Locale>) {
    return getAllSites().filter((site) => {
        return !excludeLocales.includes(site.locale);
    });
}

export function getAllSitesEnabled(sites = ALL_SITES): Site[] {
    return sites.filter((site) => {
        return !site.disabled;
    });
}

export function getAllSitesDisabled(sites = ALL_SITES): Site[] {
    return sites.filter((site) => {
        return site.disabled;
    });
}

export function getAllSitesWithLang(lang: string, sites = ALL_SITES): Site[] {
    return sites.filter((site) => {
        return site.lang === lang;
    });
}

export function getDefaultSite(): Site {
    const defaultSite = getSiteByLocale(defaultLocale, ALL_SITES);

    if (!defaultSite) {
        throw new Error(`No site found for default locale ${defaultLocale}`);
    }

    return defaultSite;
}

export function getSiteByLocale(locale: Locale, sites = ALL_SITES): Site {
    const site = findSiteByLocale(locale, sites);

    if (!site) {
        throw new Error(`No site found for locale ${locale}`);
    }

    return site;
}

export function getSiteByHost(host: string, sites = ALL_SITES): Site {
    const site = findSiteByHost(host, sites);

    if (!site) {
        throw new Error(`No site found for host ${host}`);
    }

    return site;
}

export function getSiteByLocaleEvaneos(localeEvaneos: LocaleEvaneos, sites = ALL_SITES): Site {
    const site = findSiteByLocaleEvaneos(localeEvaneos, sites);

    if (!site) {
        throw new Error(`No site found for localeEvaneos ${localeEvaneos}`);
    }

    return site;
}

export function getSiteByLocalePrismic(localePrismic: LocalePrismic, sites = ALL_SITES): Site {
    const site = findSiteByLocalePrismic(localePrismic, sites);

    if (!site) {
        throw new Error(`No site found for localePrismic ${localePrismic}`);
    }

    return site;
}

export function findSiteByLocale(locale: string, sites = ALL_SITES): Site | undefined {
    return sites.find((site) => {
        return site.locale === locale;
    });
}

export function findSiteByHost(host: string, sites = ALL_SITES): Site | undefined {
    return sites.find((site) => {
        return site.host === host;
    });
}

export function findSiteByLocaleEvaneos(
    localeEvaneos: string,
    sites = ALL_SITES,
): Site | undefined {
    return sites.find((site) => {
        return site.localeEvaneos === localeEvaneos;
    });
}

export function findSiteByLocalePrismic(
    localePrismic: string,
    sites = ALL_SITES,
): Site | undefined {
    return sites.find((site) => {
        return site.localePrismic === localePrismic;
    });
}

export function findSiteByLang(lang: string, sites = ALL_SITES): Site | undefined {
    return sites.find((site) => {
        return site.lang === lang;
    });
}

export type SiteConfig = {
    id: number;
    marketDestinationId: number;
    country: string;
    currency: Currency;
    disabled: boolean;
    host: string;
    hrefLang: string;
    lang: string;
    locale: Locale;
    localeEvaneos: LocaleEvaneos;
    localePrismic: LocalePrismic;
    optionWayUrl: string;
    railEuropeUrl: string | null;
    organisation: {
        name: string;
        description: string;
        url: string;
        logo: string;
        alternateName?: string;
        address: {
            streetAddress: string;
            addressLocality: string;
            postalCode: string;
            addressCountry: string;
        };
        founders: Founder[];
        foundingDate: string;
        contactPoint?: ContactPoint[];
        sameAs?: string[];
        legal: {
            privacy_policy: string;
        };
    };
    pages: {
        blog: Page;
        faq: Page;
        partner: Page;
    };
    partners: {
        base: Partner[];
        sustainability: Partner[];
    };
    protocol: string;
    siteVerification: {
        botify: string;
        google: string;
    };
    socialNetworks: {
        [key in SocialNetworkName]: SocialNetwork;
    };
    title: string;
    titleWithMarket: string;
};

export type Site = SiteConfig & {
    url: string;
};

export type Partner = {
    logo: {
        src: string;
        width: number;
        height: number;
    };
    url: string;
    name: string;
};

export type SocialNetworkName =
    | 'twitter'
    | 'facebook'
    | 'youtube'
    | 'googleplus'
    | 'instagram'
    | 'linkedin';

type SocialNetwork = {
    url: string;
    account?: string;
};

type Page = {
    url: string;
};

type Founder = {
    name: string;
};

type ContactPoint = {
    contactType: string;
    telephone: string;
    areaServed: string;
};
